.generalHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 163.42%;
  color: #303030;
}

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.country-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 163.42%;
  color: #5c5c5c;
}

.country-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  /* font-size: 18px; */
  /* line-height: 163.42%; */
  color: #5c5c5c;
}
.langCode {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 7%;
}

.sponsorBodyLang {
  width: 100px;
  height: 118px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.outlinedButton {
  width: 159px;
  height: 45px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #3661eb;
  border: 1px solid #3661eb;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: border-box;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  min-height: 0 !important;
  font-size: 15px !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  font-size: 2.5rem !important;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  font-size: 2.5rem !important;
}

.nameField {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  /* padding-left: 3%; */
  margin-top: 0.5%;
  /* font-family: "Poppins"; */
  font-style: normal;
  /* font-weight: 600; */
  font-size: 12px;
  line-height: 22px;
  /* color: #858585; */
  padding: 8px;
}

.selectField {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding-left: 3%;
  margin-top: 0.5%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #858585;
}

.thickHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #303030;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 17px !important;
}

.css-1j1cs9h .MuiOutlinedInput-root .MuiAutocomplete-input {
  font-size: 14px;
}

.allStudies-grid .css-1j9kmqg-MuiDataGrid-toolbarContainer {
  float: right !important;
}

.allStudies-grid .css-1w6wuh5 {
  float: right !important;
}

.study_header {
  display: flex !important;
  align-items: baseline !important;
  gap: 10px !important;
  margin-top: 12px !important;
  margin-right: -10px !important;
  margin-left: -7px !important;
}
