.sponser-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3661eb;
}

.sponsor-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #5c5c5c;
  margin-top: 1.2%;
}

.sponsor-container {
  padding-top: 1%;
}

.sponsor-secondRow {
  padding-top: 5%;
}

.sponsor-thirdRow {
  padding: 5% 1% 0 0;
}

.sponsorBack {
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  width: 250px;
  height: 49px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b;
  border-radius: 6px;
}

.sponsorBody {
  width: 79px;
  height: 118px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsorImage {
  text-align: center;
  width: 80px;
  height: 80px;
}

.sponsorSub {
  width: 200px;
  padding-top: 12%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #303030;
  /* text-align: left; */
}

.upload-body {
  margin-top: 1%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.uploadAfterBody {
  margin-left: 1%;
}

.uploadImg {
  width: 72px;
  height: 72px;
  margin-left: 2%;
}

.uploadImgAfter {
  width: 72px;
  height: 72px;
  margin-left: 2%;
  border-radius: 50%;
}

.uploadText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 163.42%;
  color: #303030;
}

.uploadField {
  margin-top: 2%;
}

.nameField {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  /* padding-left: 3%; */
  margin-top: 0.5%;
  /* font-family: "Poppins"; */
  font-style: normal;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 22px;
  /* color: #858585; */
  padding: 8px;
}

.createSponsor-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
}

.sponsorCancelButton {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #147ad6;
  width: 200.21px;
  height: 49px;
  border: 2px solid #147ad6;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: 3%;
}

.sponsorCreateButton {
  width: 87.44px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 200.21px;
  height: 49px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b;
  border-radius: 8px;
  border: none;
}

.editIcon {
  left: 70%;
  top: 65%;
  position: absolute;
  width: 20px;
  height: 19.05px;
  background: #147ad6;
  border: 0.489061px solid #e6e6e6;
  box-shadow: 0px 0px 4.89061px 2.4453px rgba(0, 0, 0, 0.1);
  border-radius: 4.89061px;
}

.uploadImg-view {
  width: 72px;
  height: 72px;
}

.uploadText-view {
  margin-left: 2%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #5c5c5c;
}

.viewSponsor-table {
  margin-top: 3%;
  width: 100%;
  height: auto;
}

.studyRow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  text-align: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #5c5c5c;
}

/* .tableBody {
  border: 1px solid #bebebe;
} */

.sponsorBackButton {
  border: none;
  width: 129px;
  height: 49px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.sponsor-firstRow {
  display: flex;
  justify-content: space-between;
}

.sponsor-secondRow {
  display: flex;
  justify-content: space-between;
}

.sponsor-thirdRow {
  display: flex;
  justify-content: end;
}

.sponsorBack {
  float: right;
}

.css-xhtqz8-MuiTableCell-root.TableCell-cellNoWrap {
  font-size: 15px;
}

.css-1o6p3b3-MuiTableCell-root.CellLayout-cellNoWrap {
  font-size: 16px;
  background-color: #eee;
  font-weight: 700;
  padding-top: 2%;
  padding-bottom: 2%;
}

.leaveSiteModal {
  background-color: #3661eb;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaveSiteHead {
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
}

.leaveSiteX {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

.leaveSiteModalBody {
  background-color: #ffffff !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-size: 15px !important;
}
.css-hyum1k-MuiToolbar-root {
  background: #3661eb;
  color: white;
  font-size: 20px !important;
}

.css-1ygcj2i-MuiTableCell-root {
  font-size: 2rem !important;
  font-weight: 700 !important;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  font-size: 2rem !important;
}

/* .css-i4bv87-MuiSvgIcon-root {
  color: rgb(223, 223, 223);
  font-size: 25px !important;
} */

.css-2ulfj5-MuiTypography-root {
  font-size: 2.5rem !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-size: 1.5rem !important;
  margin-top: 1.5%;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  font-size: 1.5rem !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  font-size: 1.5rem !important;
  margin-top: 2%;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 20px !important;
  color: rgb(0, 0, 0) !important;
}

.css-1qwsl8i-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 1.6rem !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-size: 1.5rem !important;
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  float: right !important;
}

.allSponsors-grid .css-1w6wuh5 {
  float: right !important;
}

.leaveSiteText {
  height: 150px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.navigateTextBody {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
}

.navigateText1Body {
  display: flex;
  align-items: top;
  justify-content: left;
  font-size: 16px;
}

.navigateText2Body {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
  margin-top: 2%;
}

.navigateTextButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5%;
  gap: 10px;
}

.navigateConfirmBtn {
  width: 100px;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #ffffff;
  /* width: 200.21px;
  height: 49px; */
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b;
  border-radius: 5px;
  border: none;
}

.navigateNoBtn {
  width: 100px;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #3661eb;
  /* width: 200.21px;
  height: 49px; */
  /* background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b; */
  background: #ffffff;
  border-radius: 5px;
  border: none;
  border: 1px solid #3661eb;
}

.view-sponsor-link > p {
  font-weight: 600;
}

.view-sponsor-link:hover {
  cursor: pointer;
  text-decoration: none;
}