@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@300;600;700&family=Roboto:wght@300;400;500;700&display=swap");

/* * {
  font-family: "Poppins", sans-serif;
} */

.questionnaire-link {
  display: flex;
  gap: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(54, 97, 235, 0.74);
  /* margin-left: 0.5%; */
}

.questionnaire-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #5c5c5c;
  margin-top: 2%;
}

/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  width: 320px !important;
  min-height: 40px !important;
} */

.questionnaire-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #5c5c5c;
}

.questionaire-create-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  width: 215px;
  height: 40px;
  border: none;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 4px;
  text-transform: capitalize;
  color: #ffffff;
}

.questionnaire-btn-menu
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  width: 238px;
}

.questionnaire-btn-menu .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 1.5rem !important;
}

.questionnaire-head-end {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  /* align-items: center; */
}

.questionnaire-arrow {
  margin-left: 3%;
}

.question-card-container {
  margin-top: 3%;
}

.questionnaire-card-heading {
  margin-top: 7%;
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #303030;
  text-align: center;
}

.question-card-body {
  margin-top: 2%;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 70px;
}

.admin-card {
  width: 100%;
  height: 281.39px;
  background: #ffffff;
  border: 1.15599px solid #f2f2f2;
  box-shadow: 0px 23.1199px 34.6798px rgba(167, 209, 233, 0.07);
  border-radius: 11.5599px;
}

.heading-body {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-container {
  margin-top: 3%;
}

.card-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #5c5c5c;
  margin-top: 5%;
}

.card-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
}

.cardBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  width: 130.12px;
  height: 49.75px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 5.91575px;
  border: none;
}

.img-body {
  display: flex;
  justify-content: center;
  margin-top: 8%;
}

.card-img {
  height: 48px;
  width: 50px;
}

.questionnaire-row-buttons {
  margin-top: 4%;
  margin-right: 3%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.questionnaire-edit {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #303030;
  width: 80px;
}

.questionnaire-delete {
  margin-left: 2%;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #303030;
  width: 80px;
}

.questionaire-back-btn {
  margin-top: 5%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: absolute;
  width: 238px;
  height: 44px;
  border: none;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 4px;
  text-transform: capitalize;
  color: #ffffff;
}

.react-form-builder .react-form-builder-preview {
  float: right !important;
  border: none !important;
  box-shadow: none !important;
  height: 750px !important;
  overflow-y: auto !important;
}

.react-form-builder .react-form-builder-toolbar {
  float: left !important;
  overflow-y: auto !important;
  height: 750px !important;
}

.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem {
  border: 1px dashed black;
  margin: 10px !important;
}

.react-form-builder .react-form-builder-toolbar h4 {
  display: none !important;
}

.react-form-builder .react-form-builder-toolbar ul li {
  display: flex !important;
  /* justify-content: center !important; */
  align-items: center !important;
  font-size: 16px !important;
  border: 1px dashed #b2b2b2 !important;
  height: 70px !important;
}

.react-form-builder .react-form-builder-toolbar ul li i {
  font-size: 16px !important;
}

.questionnaire-btn {
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  width: 100%;
  height: 40px;
  background-color: white;
}

.questionnaire-btn-disabled {
  border-radius: 3px;
  border: 1px solid gray !important;
  width: 100%;
  height: 40px;
  background-color: white;
}

.allQuestionnaire-grid .css-1j9kmqg-MuiDataGrid-toolbarContainer {
  float: right !important;
}

.react-form-builder-form {
  display: flex !important;
  /* align-items: center !important; */
  justify-content: center !important;
  /* height: 500px; */
  /* overflow-y: scroll !important; */
  margin-top: unset;
  margin-bottom: unset;
  padding: 2%;
}

.customWhiteBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #3661eb;
  width: 150px;
  height: 35px;
  border: 2px solid #3661eb;
  border-radius: 8px;
  background-color: #ffffff;
}

.customBlueBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border: 2px solid #3661eb;
  border-radius: 8px;
  background-color: #3661eb;
  margin-right: 3%;
}

.customBlueSaveBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  width: 100%;
  height: 44px;
  border: 2px solid #3661eb;
  border-radius: 8px;
  background-color: #3661eb;
}

/* .addTitleBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: gray;
  width: 100%;
  height: 40px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #eee;
  font-weight: 600;
}

.addTitleBtn:hover {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: white;
  width: 100%;
  height: 40px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #cecece;
  font-weight: 600;
} */

.addTitleBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: gray;
  width: 100%;
  height: 40px;
  /* border: 2px solid #ddd; */
  border-radius: 5px;
  /* background-color: #eee; */
  font-weight: 600;
  text-align: center;
}

/* .addTitleBtn:hover {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: white;
  width: 100%;
  height: 40px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #cecece;
  font-weight: 600;
} */

.saveTitleBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: white;
  width: 100%;
  height: 40px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #4d4d4d;
  font-weight: 600;
}

.customBlueFormSaveBtn {
  width: 120px;
  height: 50px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.customWhiteFormBtn {
  width: 120px;
  height: 50px;
  background: #ffffff;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #3661eb;
  border: #3661eb 1px solid;
}

.customWhiteFormBtn:hover {
  width: 120px;
  height: 50px;
  background: #3661eb;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  border: #3661eb 1px solid;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  /* font-size: 1.5rem !important; */
  /* color: black; */
}

.custom-second-div-class{
  font-size: 1.5rem !important;
}

/* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
} */

/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100% !important;
} */

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  font-size: 1.2rem !important;
  min-height: 0px !important;
} */

.btn-toolbar {
  display: flex;
  align-items: center;
  justify-content: left;
}

.modal-dialog .modal-lg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-panel {
  transform: translate3d(1000px, 392px, 0px) !important;
}

.previewContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #eee;
  /* border-radius: 10px; */
  width: 100%;
  /* padding: 4%; */
  /* padding-top: 6%; */
  margin: 0 auto;
  transition-property: width;
  transition-duration: 0.2s, 1s;
  transition-timing-function: linear, ease-in;
  transition-delay: 0s, 1s;
}

.previewGeneratorView {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #eee;
  border-radius: 10px;
  width: 100%;
  padding: 2%;
  /* padding-top: 6%; */
  margin: 0 auto;
  transition-property: width;
  transition-duration: 0.2s, 1s;
  transition-timing-function: linear, ease-in;
  transition-delay: 0s, 1s;
  z-index: 999 !important;
}

.previewGeneratorView > div > .react-form-builder-form {
  display: flex !important;
  justify-content: center !important;
  height: 600px;
  overflow-y: scroll !important;
  margin-top: 3%;
  margin-bottom: 3%;
  /* padding: 2%; */
}

.previewContainerTablet {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #eee;
  border-radius: 10px;
  width: 80%;
  padding: 4%;
  margin: 0 auto;
}

.previewContainerTab {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #eee;
  width: 910px;
  padding: 4%;
  /* padding-top: 6%; */
  margin: 0 auto;
  transition-property: width;
  transition-duration: 0.2s, 1s;
  transition-timing-function: linear, ease-in;
  transition-delay: 0s, 1s;
}

.previewBorder {
  /* margin-top: 20px; */
  border: 1px solid #e5e5e5;
  background: #e5e5e5;
  /* border-radius: 5px; */
  width: 100%;
  transition-property: width;
  transition-duration: 0.2s, 1s;
  transition-timing-function: linear, ease-in;
  transition-delay: 0s, 1s;
}

.react-form-builder-form > form {
  background-color: #ffffff;
  padding: 0 35px;
  width: 100%;
  max-width: 800px;
}



.imgIcon span{
  background: transparent !important;
}

.react-form-builder{
  padding: 0 18px 0 0;
}

/* .previewBorder .react-form-builder-form .rfb-item {
  width: 1000px !important;
} */


.buttonRadio span{
  background: transparent !important;
    font-weight: normal !important;
    padding: 0 !important;
    margin-right: 2px;
}

.react-form-builder .react-form-builder-preview .edit-form{
  width: 40rem !important;
  left: unset !important;
  right: -1000px !important;
  top: 52px !important;
  padding: 0 5px !important;
  transition: .7s !important;
}

.tabMinHeight .css-h0q0iv-MuiButtonBase-root-MuiTab-root{
  min-height: 50px !important;
}

/* .labelRadioIcon .css-j204z7-MuiFormControlLabel-root{
  margin-left: unset !important;
} */

/* .removeMargin .css-1a40jv2-MuiTypography-root{
  margin-top: unset !important;
  margin-bottom: unset !important;
} */

.removeMargin{
  margin-top: 15px;
}

.prevSpace{
  padding: 0 0 0 96px;
}

.paddPrevAge{
  padding: 0 0 0 96px;
}

.paddPrev .css-1a40jv2-MuiTypography-root{
  margin-top: unset !important;
  margin-bottom: unset !important;
}

/* .removeMargin .labelContainer span {
  white-space: nowrap;
} */

.react-form-builder .react-form-builder-preview.is-editing .edit-form{
  left: unset !important;
  right: 0 !important;
  top: 52px !important;
  transition: .7s !important;
  background-color: #e8edfb;
}

.react-form-builder .react-form-builder-toolbar ul li{
  height: unset !important;
  font-size: 12px !important;
  background: lavender;
}

.react-form-builder .react-form-builder-toolbar ul li:hover {
  background-color: #ffffff;
}

.checkboxClass .css-10hburv-MuiTypography-root{
  font-size: 1.4rem !important;
}

.previewBorder .ageRangeInputBody {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px; */
  /* height: 30px; */
}


.flexCenter{
  /* display: flex; */
  gap:20px;
  align-items: center;
}

/* .muiDatetInput .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: -10px !important;
  padding: 25px 8px !important;
} */

/* #dateRangeBody .css-igs3ac {
  padding: 20px 8px !important;
} */

/* .muiDatetInput .css-igs3ac {
  padding: 20px 8px !important;
} */

.custom-radio-class{
  margin-left: unset !important;
}

.previewNameHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #5c5c5c;
  display: flex;
  justify-content: left;
  align-items: center;
}

.previewCloseBody {
  display: flex;
  justify-content: right;
  align-items: center;
}

.previewPages {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #5c5c5c;
  display: flex;
  justify-content: left;
  align-items: center;
}

.previewBackBtn {
  width: 120px;
  height: 50px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.previewBackBtnDisabled {
  display: hidden;
  width: 120px;
  height: 50px;
  background: transparent;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: transparent;
  border: none;
}

.previewNextBtn {
  width: 120px;
  height: 50px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.previewNextDisabled {
  width: 120px;
  height: 50px;
  background: linear-gradient(0deg, #90a4e2, #90a4e2), #79a1de;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.previewCloseBtn {
  width: 120px;
  height: 50px;
  background: #ffffff;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #3661eb;
  border: #3661eb 1px solid;
}

.previewCloseBtn:hover {
  width: 120px;
  height: 50px;
  background: #3661eb;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  border: #3661eb 1px solid;
}

.confirmationNoButton {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #3661eb;
  border: #3661eb 1px solid;
}

.confirmationYesButton {
  width: 120px;
  height: 40px;
  background: #3661eb;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  border: #3661eb 1px solid;
}

.react-form-builder-form .rfb-item {
  font-size: 18px !important;
  padding-left: unset !important;
  padding-right: unset !important;
  padding: unset !important;
}

.react-form-builder-form .rfb-item .form-label {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 1.5rem !important;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  width: 300px !important;
  height: 10px !important;
} */
 

.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item
  .toolbar-header {
  z-index: 0 !important;
}

.react-form-builder-form {
  z-index: 999 !important;
}

.previewContainer {
  z-index: 999 !important;
}

.previewNameContianer {
  z-index: 999 !important;
}

.btn-toolbar {
  padding-bottom: 1% !important;
  padding-bottom: 3% !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 20px;
  height: 20px;
  border-width: thin;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  margin-right: 2%;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}

/* .css-i4bv87-MuiSvgIcon-root {
  height: 2em !important;
  width: 2em !important;
} */

input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 20px;
  height: 20px;
  border-width: thin;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  margin-right: 2%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  width: 20px;
  height: 20px;
  border-width: thin;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  margin-right: 2%;
}

.previewOptions {
  /* margin-top: 2%; */
  margin-bottom: 2%;
  /* display: flex; */
  align-items: center;
}


.layout-header{
  position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 9999;
}


.toolbar-header .is-isolated.fas.fa-edit{
  color: rgb(240, 240, 240);
}

.toolbar-header .is-isolated.fas.fa-trash{
  color: rgb(240, 240, 240);
}

.toolbar-header .is-isolated.fas{
  font-size: 14px;
}

.toolbar-header  .is-isolated.fas.fa-grip-vertical{
  color: rgb(240, 240, 240);
}

.toolbar-header .toolbar-header-buttons .btn.is-isolated{
  border-radius: 25px;
  width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.toolbar-header .toolbar-header-buttons{
  display: flex;
  gap: 6px;
}

/* .removeMargin .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 9px 12px;
} */

/* .removeMargin .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 9px 12px;
} */

.toolbar-header .toolbar-header-buttons .btn.is-isolated:nth-child(1) {
  background-color:  blue;
}

.toolbar-header .toolbar-header-buttons .btn.is-isolated:nth-child(2) {
  background-color: red;
}

/* .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root{
z-index: 13000 !important;
} */

.custom-menu-root{
  z-index: 13000 !important;
}

.custom-input-class{
  padding: 9px 12px !important;
}

.custom-Widget-class{
  font-size: 12px !important;
  padding: 9px 12px !important;
}

.custom-date-class{
  padding: 13px 12px !important;
}

.custom-typography-class{
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.custom-text-class{
  padding: 9px 12px !important;
}

.toolbar-header .toolbar-header-buttons .btn.is-isolated:nth-child(3) {
  background-color: black;
}

/* .datePadding .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 14px 12px;
} */

.layout-body{
  padding-top: 52px;
}
/* .previewDivider {
  width: 100%;
  background-color: #eee;
  height: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
} */

.iconContianer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.iconContianer > p {
  font-size: 1.3rem;
  font-weight: 600;
}

.uploadAfterBody {
  margin: 0 auto;
}

.uploadImgAfter {
  width: 170px;
  height: 100px;
  /* margin-left: 2%; */
  /* border-radius: 50%; */
}

/* .react-form-builder-form .rfb-item .form-group {
  margin-bottom: 0px;
  display: flex !important;
  flex-direction: row-reverse;
  gap: 50px;
  align-items: center !important;
  justify-content: left !important;
} */

.react-form-builder-form .rfb-item .form-label {
  /* width: 180px !important; */
  font-weight: 600 !important;
  text-align: left !important;
  /* margin-right: 1% !important;
  font-size: 1.5rem !important;
  width: 30% !important; */
}

/* .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  width: 328px !important;
} */

@media (min-width: 1500px) {
  .react-form-builder-form .rfb-item {
    width: 1000px !important;
  }

  /* .ageRangeInputBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
  } */
}

.backButton {
  width: 120px;
  height: 50px;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3.67421px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border: none;
}

/* .nameField {
  width: unset !important;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding-left: 3%;
  margin-top: 0.5%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #858585;
} */

.pageBreakInactive {
  display: none !important;
}

.hideComponent{
  display: none !important;
}

.showComponent{
  display: block !important;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root{
  font-weight: 900 ;
  font-size: 1.2rem !important;
}

/* .prevtextarea .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
  font-size: 1.5rem !important;
} */

.custom-list-item-text{
  font-size: 1.5rem !important;
}

.custom-select-class{
  padding: 9px 12px !important;
}

.pageBreakContainer {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  text-align: center;
  background-image: repeating-linear-gradient(
    -45deg,
    #9a9a9a,
    #9a9a9a 4px,
    white 4px,
    white 8px
  );
}

.pageBreakContainer > h5 {
  font-weight: 600;
  background-color: #ffffff;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 35px;
}

/* .css-72ik4y-MuiInputBase-root-MuiOutlinedInput-root {
  width: 330px !important;
} */

.leaveSiteText {
  height: 150px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 500px;
}

.navigateText1Body {
  display: flex;
  align-items: top;
  justify-content: left;
  font-size: 16px;
}

.navigateText2Body {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 15px;
  margin-top: 2%;
}

.navigateTextButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5%;
  gap: 10px;
}

.navigateConfirmBtn {
  width: 100px;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #ffffff;
  /* width: 200.21px;
  height: 49px; */
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b;
  border-radius: 5px;
  border: none;
}

.navigateNoBtn {
  width: 100px;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #3661eb;
  /* width: 200.21px;
  height: 49px; */
  /* background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #75e76b; */
  background: #ffffff;
  border-radius: 5px;
  border: none;
  border: 1px solid #3661eb;
}

.react-form-builder-form .rfb-item.alwaysbreak {
  width: 100% !important;
  height: 5px !important;
  background-color: #e1e1e1 !important;
}

.previewBorder
  .react-form-builder-form
  .rfb-item
  .form-group
  input[type="date"] {
  height: 25px !important;
}

.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem
  .form-group
  input[type="date"] {
  min-height: 25px !important;
  height: unset !important;
}

.confirmationModalHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.confirmationModalHeading > h4 {
  font-weight: 600;
}



.custom-control-input {
  /* width: 100px; */
  /* height: 100px; */
}

.css-heg063-MuiTabs-flexContainer{
  justify-content: start;
}

/* Preview Tablet Mode */
.previewBorderTab {
  margin-top: 20px;
  border: 1px solid #5a5a5a;
  border-radius: 50px;
  padding: 8px;
  transition-property: width;
  transition-duration: 0.2s, 1s;
  transition-timing-function: linear, ease-in;
  transition-delay: 0s, 1s;
}

.previewBorderTab .react-form-builder-form .rfb-item {
  width: auto !important;
}

.previewBorderTab .ageRangeInputBody {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.previewBorderTab .flexCenter {
  width: 115% !important;
}

.previewBorderTab .currentPage {
  position: absolute;
  top: -13px;
  background: white;
  width: 25%;
  font-size: 18px;
  display: flex;
  justify-content: center;
}

.previewBorderTab .react-form-builder-form .rfb-item .form-group .flexCenter {
  /* display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  width: 100%;
  height: auto; */
  /* padding: 4rem; */
}

.previewBorderTab
  .react-form-builder-form
  .rfb-item
  .form-group
  input[type="date"] {
  height: 25px !important;
}
/* Preview Tablet Mode */

/* .css-1sucic7{
  z-index: 13000 !important;
} */