.translationButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 80px;
  padding-bottom: 100px;
}

.translationImport > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  color: #ffffff;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3px;
  width: 200px;
  height: 45px;
  border: none;
}

.translationExport > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  color: #ffffff;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3px;
  width: 200px;
  height: 45px;
  border: none;
}

.validateImportBtn {
  all: unset;
  border: 2px solid #3661eb;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  background-color: #3661eb;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  width: 120px;
  height: 36px;
  text-align: center;
}

.template-import-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  gap: 10px;
  font-style: normal;
  margin-left: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  position: absolute;
  width: 352px;
  height: 37px;
  border: none;
  background: linear-gradient(90.39deg, #3661eb 3.6%, #3661eb 98.93%), #79a1de;
  border-radius: 3px;
  text-transform: capitalize;
  color: #ffffff;
}
